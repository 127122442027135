import 'core-js/stable/atob';
import { LogEventIds, logger } from '@common/logger';
import { getEsiToken } from '@common/token/index';
import SystemError from '@common/errors/systemError';
import authClaimKeys from '@common/httpClient/auth/authClaimKeys';

const failedGettingClaim = LogEventIds.authClaims + 1;
const failedReadingTimeoutClaim = LogEventIds.authClaims + 2;
const rbpTimeoutDefault = 3000;

async function getEsiTokenClaim(claimId: string): Promise<string | undefined> {
	try {
		const token = await getEsiToken();
		const tokenClaims = JSON.parse(atob(token.split('.')[1]));
		return tokenClaims[claimId];
	} catch (err) {
		logger.error(err as SystemError, {
			message: 'Failed getting claim from JWT',
			properties: { source: 'handleMoveAllRecipients.js', method: 'handleMoveAllRecipients' }
		}, failedGettingClaim);
		return undefined;
	}
}

async function getRbpTimeoutMilliseconds(): Promise<number> {
	const rbpTimeoutString = await getEsiTokenClaim(authClaimKeys.rbpTimeout);
	if (!rbpTimeoutString) {
		return rbpTimeoutDefault;
	}
	try {
		const rbpTimeoutArray = rbpTimeoutString.split(':');
		const timeoutSeconds = +rbpTimeoutArray[2]
            + 60 * +rbpTimeoutArray[1]
            + 3600 * +rbpTimeoutArray[0];
		if (timeoutSeconds) {
			return timeoutSeconds * 1000;
		}
	} catch (err) {
		logger.error(err as SystemError, {
			message: 'Failed reading rbpTimeout claim',
			properties: { source: 'handleMoveAllRecipients.js', method: 'handleMoveAllRecipients' }
		}, failedReadingTimeoutClaim);
	}
	return rbpTimeoutDefault;
}

export {
	getEsiTokenClaim,
	getRbpTimeoutMilliseconds
};
