import { LOCALSTORAGE_APP_CONFIG_STORE } from '@common/constants';
import AppConfigStore from '@common/dbStorage/appConfigStore';
import getConfig from '@common/httpClient/config/client';
import { EnhancedConfigurationInterface } from '@common/interfaces';
import AppVersionStore from '@common/dbStorage/appVersionStore';

const configStore = new AppConfigStore(LOCALSTORAGE_APP_CONFIG_STORE);

/**
 * Get the app configuration from local storage if available
 * Else get the app configuration from API and update storage
 */
const getAppConfigInfo = async (bustCache : boolean = false) : Promise<EnhancedConfigurationInterface> => {
	let cachedConfigInfo;
	if (!bustCache) {
		cachedConfigInfo = await configStore.getConfig();
	}
	if (bustCache || !cachedConfigInfo) {
		const configInfo = await getConfig();

		if (!configInfo) {
			throw new Error('Failed retrieving app config');
		}

		await configStore.updateConfig(configInfo);
		cachedConfigInfo = configInfo;
	}
	const versionStore = new AppVersionStore();

	const enhancedConfig: EnhancedConfigurationInterface = {
		...cachedConfigInfo,
		taskpaneVersion: await versionStore.getTaskpaneVersion(),
		onsendVersion: await versionStore.getOnsendVersion(),
		launcheventsVersion: await versionStore.getLauncheventsVersion()
	};
	return enhancedConfig;
};

export default getAppConfigInfo;
