import { CLIENT_STATE } from '@common/constants';
import AuthError from '@common/errors/authError';
import performanceLogger from '@common/performanceLogger';
import { OnSendErrorMode, theme } from '@common/enums';
import { ClientStateInterface, LabelsInterface, UserInterface } from '@common/interfaces';
import { OfficeProxy } from '@egress/officejs-proxy';
import getAppConfigInfo from '@common/httpClient/config/getAppConfigInfo';
import ClientStateInfoStore from '@common/dbStorage/clientState';
import { Mutex } from 'async-mutex';

const mutex = new Mutex();
const clientStateInfoStore = new ClientStateInfoStore(CLIENT_STATE);
const defaultClientState = <ClientStateInterface>{
	labels: {
		classifications: undefined,
		defaultEmailLabelId: '',
		refreshAfterUtc: ''
	},
	user: {
		switchId: '',
		auditOptions: 0,
		isPreventClient: false,
		isProtectClient: false,
		theme: theme.Automatic,
		officeTheme: undefined,
		largeFileTransferEnabled: false,
		shouldEncryptCompleteMessage: false,
		shouldPerformContentAnalysis: false,
		useMimeBuilder: false,
		refreshAfterUtc: '',
		useNaaGraphApi: true
	},
	auth: {
		isAuthenticated: false
	}
};

async function getClientState(): Promise<ClientStateInterface> {
	const clientStateObject = await clientStateInfoStore.getClientStateInfo();
	return clientStateObject || defaultClientState;
}

async function insertUpdatedState(updatedClientState: ClientStateInterface) {
	await clientStateInfoStore.updateClientStateInfo(updatedClientState);
}

async function setAuthState(isAuthenticated: boolean) {
	await mutex.runExclusive(async () => {
		const clientState = await getClientState();
		const stateObject = {
			...clientState,
			auth: {
				isAuthenticated
			}
		};
		await insertUpdatedState(stateObject);
	});
}

async function setUserState(user: object): Promise<void> {
	await mutex.runExclusive(async () => {
		const clientState = await getClientState();
		const stateObject = {
			...clientState,
			user: {
				...clientState.user,
				...user
			}
		};
		await insertUpdatedState(stateObject);
	});
}

async function setLabels(labels: LabelsInterface): Promise<void> {
	await mutex.runExclusive(async () => {
		const clientState = await getClientState();
		const updatedClientState = {
			...clientState,
			labels: {
				...clientState.labels,
				classifications: labels.labels,
				defaultEmailLabelId: labels.defaultEmailLabelId,
				refreshAfterUtc: labels.refreshAfterUtc
			}
		};
		await insertUpdatedState(updatedClientState);
	});
}

async function setUserManifestVersion(manifestVersion: string): Promise<void> {
	await mutex.runExclusive(async () => {
		const clientState = await getClientState();
		const updatedState = {
			...clientState,
			manifestVersion
		};

		await insertUpdatedState(updatedState);
	});
}

async function clientStateIsAuthenticated(state?: ClientStateInterface) {
	return (state ?? await getClientState()).auth.isAuthenticated;
}

async function getUserState(): Promise<UserInterface> {
	const timer = performanceLogger.startNew('utils.getUserState');
	const clientState = await getClientState();
	if (!await clientStateIsAuthenticated(clientState)) {
		throw new AuthError('User is not authenticated in Vuex state');
	}

	timer.stop();
	return {
		theme: clientState.user.theme,
		officeTheme: clientState.user.officeTheme,
		switchId: clientState.user.switchId,
		auditOptions: clientState.user.auditOptions,
		isPreventClient: clientState.user.isPreventClient ?? true,
		isProtectClient: clientState.user.isProtectClient ?? true,
		largeFileTransferEnabled: clientState.user.largeFileTransferEnabled ?? true,
		shouldPerformContentAnalysis: clientState.user.shouldPerformContentAnalysis ?? true,
		shouldEncryptCompleteMessage: clientState.user.shouldEncryptCompleteMessage ?? true,
		displayName: clientState.user.displayName,
		organization: clientState.user.organization,
		server: clientState.user.server,
		useMimeBuilder: clientState.user.useMimeBuilder ?? false,
		refreshAfterUtc: clientState.user.refreshAfterUtc,
		useNaaGraphApi: clientState.user.useNaaGraphApi ?? true
	};
}

async function getUserLabels() : Promise<LabelsInterface> {
	const timer = performanceLogger.startNew('utils.getUserLabels');
	const clientState = await getClientState();
	if (!await clientStateIsAuthenticated(clientState)) {
		throw new AuthError('User is not authenticated in Vuex state');
	}

	timer.stop();
	return clientState?.labels;
}

async function getUserManifestVersion(): Promise<string> {
	const timer = performanceLogger.startNew('utils.getUserManifestVersion');
	const clientState = await getClientState();
	if (!await clientStateIsAuthenticated(clientState)) {
		throw new AuthError('User is not authenticated in Vuex state');
	}

	timer.stop();
	return clientState?.manifestVersion;
}

async function getHostLocation(): Promise<string> {
	const timer = performanceLogger.startNew('utils.getHostLocation');
	const config = await getAppConfigInfo();
	timer.stop();

	return config.appInformation.hostLocation;
}

async function getAppVersion(): Promise<string> {
	const timer = performanceLogger.startNew('utils.getAppVersion');
	const config = await getAppConfigInfo();
	timer.stop();

	return config.appInformation.appVersion;
}

async function getOnSendErrorMode(): Promise<OnSendErrorMode> {
	const timer = performanceLogger.startNew('utils.getOnSendErrorMode');
	const config = await getAppConfigInfo();
	timer.stop();

	return config.appInformation.blockOnSendWhenError
		? OnSendErrorMode.Block : OnSendErrorMode.Allow;
}

async function getOnSendErrorTags(): Promise<string[]> {
	const timer = performanceLogger.startNew('utils.getOnSendErrorTags');
	const config = await getAppConfigInfo();
	timer.stop();

	return config.appInformation.onSendErrorTags ?? [];
}

async function getSuppressAutoLoginForUserIds(): Promise<string[]> {
	const timer = performanceLogger.startNew('utils.getSuppressAutoLoginForUserIds');
	const config = await getAppConfigInfo();
	timer.stop();

	return config.appInformation.suppressAutoLoginForUserIds ?? [];
}

function isOnlineEmailClient(): boolean {
	return OfficeProxy.getPlatformType()?.toString().toLowerCase() === 'officeonline';
}

export {
	defaultClientState,
	setUserState,
	setAuthState,
	setLabels,
	getUserState,
	getUserLabels,
	getAppVersion,
	getHostLocation,
	setUserManifestVersion,
	getUserManifestVersion,
	clientStateIsAuthenticated,
	getOnSendErrorMode,
	getOnSendErrorTags,
	getSuppressAutoLoginForUserIds,
	isOnlineEmailClient
};
