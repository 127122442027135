import IndexedDbRepository from '@common/dbStorage/indexedDbStore';
import { LOCALSTORAGE_PROMPT_DATA_TRANSFER_STORE } from '@common/constants';
import { PromptDataTransfer } from '@common/interfaces';

export default class PromptDataTransferStore {
	store: IndexedDbRepository<PromptDataTransfer>;

	/**
     * Create an instance of the PromptDataTransferStore class
     */
	constructor() {
		// 300,000 ms is 5 minutes which is the max life of a send event
		this.store = new IndexedDbRepository(LOCALSTORAGE_PROMPT_DATA_TRANSFER_STORE, 300000);
	}

	/**
     * Retrieves the value of the PromptDataTransfer with the given hash
     * @returns {object | StorageError}
     */
	async getPromptDataTransfer(key: string): Promise<PromptDataTransfer | null> {
		const found = await this.store.get(key);
		if (!found) return null;
		return found;
	}

	/**
     * Updates the value of the PromptDataTransfer with the given hash
     * @param {object} promptData The value to update the config with
     * @returns {void | StorageError}
     */
	async updatePromptDataTransfer(key: string, promptData: PromptDataTransfer): Promise<void> {
		await this.store.update(key, promptData);
	}

	/**
     * Removes the PromptDataTransfer with the given hash from the store.
     * Does nothing if such an PromptDataTransfer doesn't exist
     * @returns {void | StorageError}
     */
	async removePromptDataTransfer(key: string): Promise<void> {
		await this.store.remove(key);
	}

	/**
     * Drops the instance of the created store.
     * @returns {void | StorageError}
     */
	async dropStore(): Promise<void> {
		await this.store.dropStore();
	}

	static async storePromptData(promptDataTransfer: PromptDataTransfer): Promise<string> {
		const promptTime = Date.now().toString();
		const promptDataStore = new PromptDataTransferStore();
		await promptDataStore.updatePromptDataTransfer(promptTime, promptDataTransfer);
		return promptTime;
	}

	static async removePromptData(promptTime: string): Promise<void> {
		const promptDataStore = new PromptDataTransferStore();
		await promptDataStore.removePromptDataTransfer(promptTime);
	}
}
