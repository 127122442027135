class SystemError extends Error {
	localizationId?: string | null;

	errorCode?: string | null;

	userEventId?: string | null;

	code?: number | null;

	constructor(message: string, localizationId?: string, errorCode?: string, userEventId?: string) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
		this.code = parseInt(errorCode ?? '0', 10);
	}
}

export default SystemError;
