import SystemError from '@common/errors/systemError';

class AuthError extends SystemError {
	constructor(message: string, localizationId?: string, errorCode?: string, userEventId?: string) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
	}
}

export default AuthError;
