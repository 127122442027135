import { RBP_HEADER, ITEM_DATA_STATE } from '@common/constants';
import { OfficeProxy } from '@egress/officejs-proxy';
import { v4 as uuidv4 } from 'uuid';
import { LogEventIds, logger } from '@common/logger';
import SystemError from '@common/errors/systemError';
import performanceLogger from '@common/performanceLogger';
import { ExtendedInternetHeaders } from '@common/interfaces';
import ItemDataStore from '@common/dbStorage/itemDataStore';

const failedCachingOpinionContextId = LogEventIds.opinionId + 1;
const failedLoadingOpinionContextId = LogEventIds.opinionId + 2;
const failedLoadingOpinionIds = LogEventIds.opinionId + 3;
const failedRemovingOpinionContextId = LogEventIds.opinionId + 4;

const itemDataStore = new ItemDataStore(ITEM_DATA_STATE);

/**
 * Generates and sets the Prevent opinion context ID for the current message
 * @returns {string|null} The generated opinion context ID or null if generation failed
 */
export async function setOpinionContextId(): Promise<string> {
	try {
		const opinionContextUuid = uuidv4();
		const newOpinionContextId = opinionContextUuid.replace(/-/g, '');
		const proxy = new OfficeProxy();
		await proxy.setItemInternetHeadersAsync({ [RBP_HEADER]: newOpinionContextId });
		return newOpinionContextId;
	} catch (err) {
		logger.error(err as SystemError, {
			message: 'Failed to cache opinion context id.',
			properties: { source: 'opinionContextId.js', method: 'setOpinionContextId' }
		}, failedCachingOpinionContextId);
		throw err;
	}
}

/**
 * Removes the prevent opinionContextId from the internetHeader
 * @returns {void}
 */
export async function removeOpinionContextId(): Promise<void> {
	try {
		const proxy = new OfficeProxy();
		await proxy.removeItemInternetHeadersAsync([RBP_HEADER]);
	} catch (err) {
		logger.error(err as SystemError, {
			message: 'Failed to remove opinionContextId from internet header.',
			properties: { source: 'opinionId.js', method: 'removeOpinionContextId' }
		}, failedRemovingOpinionContextId);
		throw err;
	}
}

/**
 * loads opinionContextId and sets it if it doesn't exist
 * @returns {string|null}
 */
export async function loadOpinionContextId(): Promise<string> {
	const timer = performanceLogger.startNew('utils.loadOpinionContextId');
	try {
		let opinionContextId;
		const proxy = new OfficeProxy();
		const headers =	<ExtendedInternetHeaders> await proxy.getItemInternetHeadersAsync([RBP_HEADER]);

		opinionContextId = headers?.[RBP_HEADER];
		if (!opinionContextId) {
			opinionContextId = await setOpinionContextId();
		}
		return opinionContextId;
	} catch (err) {
		logger.error(err as SystemError, {
			message: 'Failed to load cached opinion context id.',
			properties: { source: 'opinionContextId.js', method: 'loadOpinionContextId' }
		}, failedLoadingOpinionContextId);
		throw err;
	} finally {
		timer.stop();
	}
}

/**
 * Retrieves the list of cached opinion ids
 * @returns {string[]}
 */
export async function getCachedOpinionIds(restId: string): Promise<string[]> {
	const timer = performanceLogger.startNew('utils.getCachedOpinionIds');
	try {
		const itemData = await itemDataStore.getItemData(restId);
		return itemData?.CACHED_OPINION_IDS_PROPERTY ?? [];
	} catch (err) {
		logger.error(err as SystemError, {
			message: 'Failed to load cached opinion ids.',
			properties: { source: 'opinionId.js', method: 'getCachedOpinionIds' }
		}, failedLoadingOpinionIds);
		throw err;
	} finally {
		timer.stop();
	}
}

/**
 * Inserts the given opinionId to the list of cached opinionIds
 * @returns {void}
 */
export async function insertCachedOpinionId(restId: string, opinionId: string): Promise<void> {
	try {
		itemDataStore.updateItemData(
			restId,
			{ CACHED_OPINION_IDS_PROPERTY: [...await getCachedOpinionIds(restId), opinionId] }
		);
	} catch (err) {
		logger.error(err as SystemError, {
			message: 'Failed to insert new cached opinion id.',
			properties: { source: 'opinionId.js', method: 'insertCachedOpinionId' }
		}, failedCachingOpinionContextId);
		throw err;
	}
}
