import { OfficeProxy } from '@egress/officejs-proxy';
import { getMessageMimeContentAsync, getMessageAttachmentContentAsync } from '@common/httpClient/outlook/client';
import { MimeAttachment } from '@common/interfaces';
import AccountManager from '@common/utils/naaTokenGetter';
import { getUserState } from '@common/utils/clientState';

const updateMessage = () => new Promise((resolve, reject) => {
	Office.context.mailbox.item?.saveAsync(async (asyncResult) => {
		if (asyncResult.status === Office.AsyncResultStatus.Failed) {
			reject(asyncResult.error);
		} else {
			resolve(asyncResult.value);
		}
	});
});

const shouldUseNaaGraphApi = async ():Promise<boolean> => {
	const {
		useNaaGraphApi
	} = await getUserState();
	return useNaaGraphApi ?? true;
};
interface RestApiProperties {
	userPath: string;

	restUrl: string;

	authToken: string;

	apiVersion: string;

	restId: string;
}

const getRestApiProperties = async () => {
	const proxy = new OfficeProxy();
	let props: RestApiProperties;
	if (await shouldUseNaaGraphApi()) {
		const accountManager = new AccountManager();
		await accountManager.initialize();
		const userEmail = await proxy.getItemFromAsync();
		props = {
			userPath: `users/${userEmail.emailAddress}`,
			restUrl: 'https://graph.microsoft.com',
			authToken: await accountManager.ssoGetToken(),
			apiVersion: 'v1.0',
			restId: await proxy.getItemRestIdAsync()
		};
	} else {
		props = {
			userPath: 'me',
			restUrl: proxy.getRestUrl(),
			authToken: await proxy.getRestTokenAsync(),
			apiVersion: 'v2.0',
			restId: await proxy.getItemRestIdAsync()
		};
	}
	return props;
};

const getMessageBodyAsync = async (): Promise<string> => {
	try {
		const restProps: RestApiProperties = await getRestApiProperties();

		// Save message details to ensure getting latest copy of message
		await updateMessage();

		// formulate the messageUrl query to retrieve only message body
		const messageUrl = `${restProps.restUrl}/${restProps.apiVersion}/${restProps.userPath}/messages/${restProps.restId}?$select=Body`;
		const message = await getMessageMimeContentAsync(messageUrl, restProps.authToken, 'outlook.body-content-type="text"');

		if (await shouldUseNaaGraphApi()) {
			return message.body.content;
		}
		return message.Body.Content;
	} catch (error) {
		// Dont throw error, best effort approach
		return '';
	}
};

const getMessageBodyOfTypeAsync = async (bodyType: string): Promise<string> => {
	// allow to fail - LFT fails closed
	const restProps: RestApiProperties = await getRestApiProperties();

	// Save message details to ensure getting latest copy of message
	await updateMessage();

	// formulate the messageUrl query to retrieve only message body
	const messageUrl = `${restProps.restUrl}/${restProps.apiVersion}/${restProps.userPath}/messages/${restProps.restId}?$select=Body`;
	const message = await getMessageMimeContentAsync(messageUrl, restProps.authToken, bodyType);

	if (await shouldUseNaaGraphApi()) {
		return message.body.content;
	}
	return message.Body.Content;
};

const getMessageAttachmentsAsync = async (): Promise<MimeAttachment[]> => {
	// allow to fail - LFT fails closed
	const restProps: RestApiProperties = await getRestApiProperties();

	// Save message details to ensure getting latest copy of message
	await updateMessage();

	// formulate the messageUrl query to retrieve only message body
	const messageUrl = `${restProps.restUrl}/${restProps.apiVersion}/${restProps.userPath}/messages/${restProps.restId}/attachments`;
	const message = await getMessageAttachmentContentAsync(messageUrl, restProps.authToken);

	if (await shouldUseNaaGraphApi()) {
		return message.value.filter((attachment: any) => attachment.isInline).map((attachment: any) => ({
			id: attachment.contentId,
			type: attachment.contentType,
			name: attachment.name,
			content: attachment.contentBytes,
			size: attachment.size
		}));
	}
	return message.value.filter((attachment: any) => attachment.IsInline).map((attachment: any) => ({
		id: attachment.ContentId,
		type: attachment.ContentType,
		name: attachment.Name,
		content: attachment.ContentBytes,
		size: attachment.Size
	}));
};

const requiresOutlookRest = async (): Promise<boolean> => {
	const proxy = new OfficeProxy();
	const clientInfo = OfficeProxy.getClientInformation();
	const composeType = await proxy.getItemComposeTypeAsync();
	const isNewEmail = (composeType.toLowerCase() === 'newmail');
	return ((clientInfo.platform.toLowerCase() === 'mac' || ['outlookwebapp', 'newoutlookwindows'].includes(clientInfo.hostName.toLowerCase())) && !isNewEmail);
};

export {
	getMessageBodyAsync,
	getMessageBodyOfTypeAsync,
	getMessageAttachmentsAsync,
	requiresOutlookRest,
	updateMessage
};
