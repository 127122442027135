import SystemError from '@common/errors/systemError';

class ValidationError extends SystemError {
	constructor(message: string, localizationId?: string, errorCode?: string) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
	}
}

export default ValidationError;
