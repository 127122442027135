/* eslint-disable max-classes-per-file */
import SystemError from '@common/errors/systemError';

class RbpError extends SystemError {
	constructor(
		message: string,
		localizationId?: string,
		errorCode?: string | null,
		userEventId?: string | null
	) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
		this.code = parseInt(errorCode ?? '0', 10);
	}
}

class RbpForbiddenError extends SystemError {
	constructor(
		message: string,
		localizationId?: string,
		errorCode?: string | null,
		userEventId?: string | null
	) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
	}
}
class RbpTimeoutError extends SystemError {
	constructor(
		message: string,
		localizationId?: string,
		errorCode?: string | null,
		userEventId?: string | null
	) {
		super(message);
		this.localizationId = localizationId;
		this.errorCode = errorCode;
		this.userEventId = userEventId;
	}
}

export {
	RbpError,
	RbpForbiddenError,
	RbpTimeoutError
};
