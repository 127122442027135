import { theme } from '@common/enums';
import { UIUserInterface } from '@/interfaces';
import { isOutlookThemeDark } from '@common/utils/theme';

export default {
	darkModeEnabled: (state: UIUserInterface) => {
		switch (state.theme) {
		case theme.Dark:
			return true;
		case theme.Light:
			return false;
		case theme.Automatic:
		default:
			return isOutlookThemeDark(state.officeTheme);
		}
	},
	switchId: (state: UIUserInterface) => state.switchId,
	isPreventClient: (state: UIUserInterface) => state.isPreventClient,
	isProtectClient: (state: UIUserInterface) => state.isProtectClient,
	largeFileTransferEnabled: (state: UIUserInterface) => state.largeFileTransferEnabled,
	shouldEncryptCompleteMessage: (state: UIUserInterface) => state.shouldEncryptCompleteMessage,
	useMimeBuilder: (state: UIUserInterface) => state.useMimeBuilder,
	useNaaGraphApi: (state: UIUserInterface) => state.useNaaGraphApi,
	refreshAfterUtc: (state: UIUserInterface) => state.refreshAfterUtc,
	organization: (state: UIUserInterface) => state.organization,
	server: (state: UIUserInterface) => state.server
};
