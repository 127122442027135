import { getEsiToken } from '@common/token';
import { OfficeProxy } from '@egress/officejs-proxy';
import { refreshPolicies } from '@common/httpClient/policies/client';
import isEqual from 'lodash/isEqual';
import {
	UPDATE_OFFICE_THEME,
	SET_OFFICE_THEME,
	SET_THEME,
	UPDATE_USER_THEME,
	UPDATE_POLICIES_REQUESTED,
	UPDATE_POLICIES_SUCCESS,
	UPDATE_POLICIES_FAILED
} from '@/store/types';
import { UIUserInterface } from '@/interfaces';
import { setUserState, setAuthState } from '@common/utils/clientState';

const officeProxy = new OfficeProxy();

export default {
	async [UPDATE_OFFICE_THEME]({ commit, state }
		: {commit: any, state: UIUserInterface}): Promise<void> {
		if (!OfficeProxy || OfficeProxy.isRunningInDialog()) {
			return;
		}
		const currentTheme = officeProxy.getTheme();

		if (!isEqual(state.officeTheme, currentTheme)) {
			commit(SET_OFFICE_THEME, currentTheme);
		}
	},
	async [UPDATE_USER_THEME]({ commit }: any, payload: any): Promise<void> {
		setUserState({ theme: payload });
		commit(SET_THEME, payload);
	},
	async [UPDATE_POLICIES_REQUESTED]({ commit }: any): Promise<void> {
		try {
			commit(UPDATE_POLICIES_REQUESTED);

			const token = await getEsiToken();
			const userPolicies = await refreshPolicies(token);
			await setUserState({ ...userPolicies });
			await setAuthState(true);

			commit(UPDATE_POLICIES_SUCCESS, userPolicies);
		} catch (err) {
			commit(UPDATE_POLICIES_FAILED, err);
		}
	}
};
