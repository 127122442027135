import SystemError from '@common/errors/systemError';

class FetchError extends SystemError {
	constructor(message: string, localizationId?: string) {
		super(message);
		this.localizationId = localizationId;
	}
}

export default FetchError;
