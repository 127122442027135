import hash from 'object-hash';
import { CapturedMatchStructure, RecipientInterface } from '@common/interfaces';
import performanceLogger from '@common/performanceLogger';

export default async function getMessageHash(
	restId: string,
	recipients: RecipientInterface[],
	keywords: string[] = [],
	capturedMatches: CapturedMatchStructure[] = []
): Promise<string> {
	const timer = performanceLogger.startNew('utils.getMessageHash');

	const to = recipients.filter((x) => x.type === 1).map((r) => r.email).sort();
	const cc = recipients.filter((x) => x.type === 2).map((r) => r.email).sort();
	const bcc = recipients.filter((x) => x.type === 3).map((r) => r.email).sort();
	const hashSource = {
		restId,
		keywords,
		capturedMatches,
		to,
		cc,
		bcc
	};

	timer.stop();
	/**
	 * Getting hashed value for object potentially give different value for same object
	 * due to both objects failing equlity check (i.e. A !== B)
	 * but hashed value is always consistent for same string variables
	 */
	return hash(JSON.stringify(hashSource));
}
