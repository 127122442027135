import getOpinion from '@common/httpClient/rbp/client';
import { insertCachedOpinionId, loadOpinionContextId } from '@common/utils/opinionId';
import { getEsiToken } from '@common/token';
import { RbpContext } from '@common/enums';
import OpinionStore from '@common/dbStorage/opinionStore';
import { LOCALSTORAGE_OPINION_STORE } from '@common/constants';
import getOpinionBodyBuilder from '@common/httpClient/rbp/getOpinionBodyBuilder';
import { OfficeProxy } from '@egress/officejs-proxy';
import getMessageHash from '@common/utils/getMessageHash';
import { RbpError } from '@common/errors/rbpError';
import {
	RBP_OPINION_REQUESTED, RBP_OPINION_SUCCESS, RBP_OPINION_FAILED,
	RBP_OPINION_RESET, STORE_OPINION_CONTEXT_ID, STORE_REST_ID
} from '@/store/types';
import { StoreInterface, RbpInterface } from '@/interfaces';
import { getCommonEmailAttributes } from '@common/utils/getCommonEmailAttributes';

const opinionStore = new OpinionStore(LOCALSTORAGE_OPINION_STORE);

export default {
	async [RBP_OPINION_REQUESTED]({ rootState, state, commit }
		: {rootState: StoreInterface, state: RbpInterface, commit: any}) {
		try {
			commit(RBP_OPINION_REQUESTED);
			const officeProxy = new OfficeProxy();
			if (!rootState.restId) {
				commit(STORE_REST_ID, await officeProxy.getItemRestIdAsync());
			}

			// generate a hash of relevant message details
			if (!state.opinionContextId) {
				try {
					commit(STORE_OPINION_CONTEXT_ID, await loadOpinionContextId());
				} catch (err) {
					throw new RbpError(
						'Unable to load opinionContextId',
						'rbp.opinionContextIdMissing',
						null,
						null
					);
				}
			}

			// check for previously obtained opinions
			const { switchId } = rootState.user;
			const {
				from, recipients, subject, body, originalRecipients
			} = await getCommonEmailAttributes(rootState.restId, switchId, state.opinionContextId);

			const hash = await getMessageHash(rootState.restId, recipients);
			const cachedOpinion = await opinionStore.getOpinion(hash);
			if (cachedOpinion) {
				commit(RBP_OPINION_SUCCESS, cachedOpinion);
				return;
			}

			const getOpinionBody = await getOpinionBodyBuilder({
				from,
				recipients,
				restId: rootState.restId,
				originalRecipients: originalRecipients!,
				body: body!,
				subject: subject!,
				labels: {
					classifications: rootState.labels.classifications,
					defaultEmailLabelId: rootState.labels.defaultEmailLabelId,
					refreshAfterUtc: rootState.labels.refreshAfterUtc,
					err: null
				},
				switchId,
				opinionContextId: state.opinionContextId,
				rbpContext: [RbpContext.Details]
			});

			if (!getOpinionBody?.commonEmailAttributes?.recipients?.length) {
				commit(RBP_OPINION_RESET);
				return;
			}

			const token = await getEsiToken();
			const response = await getOpinion(getOpinionBody, token, rootState.restId);
			if (response?.opinionId) {
				await insertCachedOpinionId(rootState.restId, response?.opinionId);
			}
			commit(RBP_OPINION_SUCCESS, response);
			if (hash) {
				await opinionStore.updateOpinion(hash, response);
			}
		} catch (err) {
			commit(RBP_OPINION_FAILED, err);
			throw err;
		}
	}
};
