import { theme as themeEnum } from '@common/enums';
import { getUserState } from './clientState';

function isOutlookThemeDark(officeTheme: Office.OfficeTheme | undefined): boolean {
	if (!officeTheme?.bodyBackgroundColor) {
		// officeTheme is only supported in Office on Windows
		return false;
	}

	const colour = officeTheme.bodyBackgroundColor;
	const r = parseInt(colour.substr(1, 2), 16);
	const g = parseInt(colour.substr(3, 2), 16);
	const b = parseInt(colour.substr(5, 2), 16);
	// 50% grey -> 127+127+127 = 381;
	return r + g + b < 381;
}

const isThemeDarkMode = (themeValue: string, officeTheme: Office.OfficeTheme | undefined): boolean => {
	switch (themeValue) {
		case themeEnum.Dark:
			return true;
		case themeEnum.Light:
			return false;
		case themeEnum.Automatic:
		default:
			return isOutlookThemeDark(officeTheme);
	}
};

const getEvaluatedTheme = async () : Promise<string> => {
	const { theme, officeTheme } = await getUserState();
	return isThemeDarkMode(theme, officeTheme) ? themeEnum.Dark : themeEnum.Light;
};

export { getEvaluatedTheme, isOutlookThemeDark };
